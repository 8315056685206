import React from 'react'

/* eslint-disable react/jsx-no-comment-textnodes */
const PressPackage = () => {
  return (
    <div className="pt-5 pb-15 md:py-15 inner-container">
      <div className="flex flex-col items-center text-center">
        <p className="subtitle">// Media</p>
        <h2 className="mt-2.5">Writing about Xanadu?</h2>
        <p className="mt-5 md:w-128">
          Get the latest brand assets and company facts.
        </p>
        <div className="mt-10 -mb-5">
          <a
            href="mailto:press@xanadu.ai"
            target="_blank"
            className="mb-5 mx-2.5 btn btn-lg btn-1"
            rel="noopener noreferrer"
          >
            Press contact
          </a>
          <a
            href="https://drive.google.com/drive/folders/1ZmeDhFq7cCvIhkOLZ9RX2cgk_p4h8hmP?usp=sharing"
            target="_blank"
            className="mx-2.5 btn btn-lg btn-2"
            rel="noopener noreferrer"
          >
            Brand assets
          </a>
        </div>
      </div>
    </div>
  )
}

export default PressPackage
