import * as React from 'react'

import PressBox from './PressBox'

type PressBoxesProps = {
  articles: PressArticleQuery[]
}

const PressBoxes = ({ articles }: PressBoxesProps) => {
  return (
    <div className="flex flex-row flex-wrap -m-10.5">
      {articles.map((item, index) => {
        return (
          <div className="w-full lg:w-1/3 p-10.5" key={index}>
            <PressBox article={item} index={index} />
          </div>
        )
      })}
    </div>
  )
}

export default PressBoxes
