/* eslint-disable react/jsx-no-comment-textnodes */
import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/Layout'
import PressBoxes from '../components/press/PressBoxes'
import PressPackage from '../components/press/PressPackage'
import Seo from '../components/Seo'
import { PressArticleQuery } from 'types'

type PressPageProps = {
  data: {
    allMdx: {
      nodes: PressArticleQuery[]
    }
  }
}

const PressPage = ({ data }: PressPageProps) => {
  const articles = data.allMdx.nodes.filter(
    (node) => node.fields.collection === 'press'
  )
  const articlesVisibleIteration = 12 // number of posts to show each button press
  const articlesLength = articles.length

  // STATE
  const [articlesVisible, setArticlesVisible] = React.useState(
    articlesVisibleIteration
  )

  const showMore = () => {
    setArticlesVisible(articlesVisible + articlesVisibleIteration)
  }

  const renderShowMoreButton = () => {
    if (articlesVisible >= articlesLength) return null
    return (
      <button className="btn btn-lg btn-1" onClick={showMore}>
        Load more
      </button>
    )
  }

  return (
    <Layout>
      {/* HERO */}
      <div className="py-32 bg-press-tbd">
        <div className="inner-container">
          <p className="text-white subtitle opacity-70">// Press</p>
          <div className="text-white lg:w-5/12">
            <h1>Welcome to Xanadu&apos;s newsroom</h1>
          </div>
        </div>
      </div>

      {/* ALL PRESS */}
      <div className="overflow-hidden standard-page-section">
        <div className="inner-container">
          <h2>Articles</h2>
          <div className="mt-20">
            <PressBoxes articles={articles.slice(0, articlesVisible)} />
          </div>
          <div className="mt-20 text-center">{renderShowMoreButton()}</div>
        </div>
      </div>

      <PressPackage />
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
          slug
        }
        fields {
          collection
        }
        id
        slug
        excerpt
      }
    }
  }
`

export default PressPage

export const Head = () => {
  return (
    <Seo
      title="Press"
      description="Read about Xanadu's latest milestones"
      image="press_hero_bg.jpg"
    />
  )
}
