import * as React from 'react'
import { Link } from 'gatsby'

export type PressBoxProps = {
  article: PressArticleQuery
  index: number
}

const PressBox = ({ article }: PressBoxProps) => {
  const { frontmatter } = article
  return (
    <>
      <h5 className="h5-sm">
        <Link
          to={`/press/${frontmatter.slug}`}
          aria-label={`${frontmatter.title}, ${frontmatter.date}`}
        >
          {frontmatter.title}
        </Link>
      </h5>
      <Link
        to={`/press/${frontmatter.slug}`}
        className="block mt-5 font-semibold border-b-2 swipe-link swipe-link-2 border-primary-600"
        aria-label={`${frontmatter.title}, ${frontmatter.date}`}
      >
        <span data-hover={frontmatter.date}>{frontmatter.date}</span>
      </Link>
    </>
  )
}

export default PressBox
